.Impact {
  padding: 190px 50px 100px;

  &__text {
    font-size: 32px;
    line-height: 48px;
  }

  &__box {
    background: #f4f5f8;
    border: 1px solid #dadbe2;
    border-radius: 20px;
    max-width: 352px;
    height: 205px;
  }

  &__container {
    max-width: 1096px;
  }

  &__label {
    font-size: 22px;
    line-height: 28px;
  }

  &__count {
    font-size: 45px;
    line-height: 68px;
  }

  &__border {
    border-top: 1px solid #dadbe2;
  }
}
