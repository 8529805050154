.FileUploader {
  // width: 671px;
  // padding: 150px 0 0;
  // background: #f4f5f8;
  // color: #415473;
  padding-top: 150px;

  &__upload {
    cursor: pointer;
    border: 3px solid #5eb5ca;
    border-radius: 10px;
    padding: 14px 18px;
    font-size: 18px;
    line-height: 27px;
    transition: color 0.25s, border-color 0.25s;

    &:hover {
      color: #fff;
      background-color: #5eb5ca;
    }
  }

  &__link {
    &:hover {
      color: #415473;
    }
  }
}
