.RichTextEditor {
  .ql-container.ql-snow {
    border: 1px solid #c4c5d0;
    border-radius: 2px;
  }
  .ql-editor {
    font-family: 'Poppins', sans-serif;
    color: #072136;
    width: calc(100% - 10px);
    margin-top: 20px;
    padding-left: 30px;
    padding-top: 0;
    height: 300px;

    h1 {
      font-size: 32px;
      line-height: 1.2;
    }
    h2 {
      font-size: 22px;
      line-height: 28px;
    }

    h3 {
      font-size: 20px;
      line-height: 24px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .ql-editor::-webkit-scrollbar {
    width: 8px;
  }

  /* Scrollbar */
  .ql-editor::-webkit-scrollbar-track {
    background-color: #dadbe2;
    border-radius: 40px;
  }

  /* Handle */
  .ql-editor::-webkit-scrollbar-thumb {
    background: #415473;
    border-radius: 40px;
  }

  /* Handle on hover */
  .ql-editor::-webkit-scrollbar-thumb:hover {
    background: darken(#415473, 5);
  }
}
