.small-button-wrapper {
    flex-wrap: wrap;
    display: grid;
    padding-top: 10px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-gap: 5px;

    .small-button {
        align-items: center;
        background-color: #F4F5F8;
        padding: 5px 15px 5px 15px;
        margin-right: 10px;
        border-radius: 10px;
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 400;
        color: #415473;
        cursor: pointer;
        min-width: 100px;
        // width: auto;
        overflow-wrap: break-word;
        min-height: 60px;
        white-space: normal;
    }

    .small-button:hover {
        background-color: #9edcec;
        // opacity: 0.15;
        color: #5EB5CA;
    }
}