.Dashboard {
  padding: 110px 25px 150px;

  &__title {
    font-size: 45px;
    line-height: 68px;
  }

  &__item {
    text-decoration: none;
    border: 2px solid #dadbe2;
    border-radius: 30px;
    padding-bottom: 22px;
    width: 284px;
    height: 237px;
    color: #415473;
    background-color: #fff;
    transition: border-color 0.25s, color 0.25s;
    svg {
      path {
        transition: stroke 0.25s;
      }
    }

    &:hover {
      color: #5eb5ca;
      border-color: #5eb5ca;
      svg {
        path {
          stroke: #5eb5ca;
        }
      }
    }
  }

  &__crime {
    svg {
      path {
        transition: fill 0.25s;
      }
    }
    &:hover {
      color: #5eb5ca;
      border-color: #5eb5ca;
      svg {
        path {
          fill: #5eb5ca;
        }
      }
    }
  }

  .row {
    margin: 0 -25px;
  }
  .col {
    padding: 0 25px;
  }

  &__lastRow {
    .col:nth-child(even) {
      text-align: start;
    }
    .col:nth-child(odd) {
      text-align: end;
    }
  }
}
