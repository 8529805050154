.pricing {
    padding-top: 150px;

    .pricing-desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        text-align: center;
        color: #415473;
    }

    .plan-card-wrap {
        margin-top: 146px;
        margin-bottom: 260px;
        display: flex;
        gap: 31px;
        justify-content: center;

        .plan-card {
            flex-grow: 0;
            flex-basis: 350px;
            margin-top: 40px;
            padding: 50px 30px;
            color: #415473;
            background: #F4F5F8;
            border: 1px solid #DADBE2;
            border-radius: 20px;

            &.active {
                color: #fff;
                background: #415473;
            }

            .plan-title {
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                text-align: center;
            }

            .plan-price {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                font-weight: 700;
                color: #5EB5CA;
                line-height: 100%;

                .plan-price-digit {
                    font-size: 65px;
                    height: 65px;
                    line-height: 1;
                }

                .plan-price-text {
                    line-height: 2.5;
                    font-size: 18px;
                }
            }

            .plan-points {
                list-style: none;
                padding: 0;
                margin: 50px 18px 0;

                CheckIcon {
                    margin-right: 10px;
                }

                li {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    margin-top: 20px;
                }
            }
        }
    }
}