.Preview {
  .table {
    td {
      border: 0;
      min-width: 174px;
    }

    td:first-child {
      border: 0;
      width: 174px !important;
      min-width: unset !important;
    }

    tr {
      border-bottom: 1px solid #e0e3e7;
    }

    td:first-child {
      border-right: 1px solid #e0e3e7;
    }
  }
}

.custom-modal {
  height: 489px;
  width: 1165px;
  color: #1D2132;
}
.custom-light-container{
  display: flex;
  color: #1D2132;
}
.custom-light-div {
  flex: 1;
  width: 50%;
  margin-top: 10%;
  justify-content: center;
  justify-items: center;
  align-items: center;
  color: #1D2132;

  .custom-title{
    font-weight: 700;
    font-size: 32px;
    align-items: center;
    line-height: 48px;
    color: #1D2132;
  }
  .custom-value{
    margin-top: 30%;
  }
  .custom-post-box{
    font-size: 32px;
    margin-top: 50px;
    max-height: 400px;
    height: 100%;

    .RichTextEditor {
      .ql-editor {
        font-family: 'Poppins', sans-serif;
        color: #1D2132;
        width: calc(100% - 10px);
        margin-top: 20px;
        padding-left: 30px;
        padding-top: 0;
        height: 100px;
        border-radius: 30px;
      }
    }
  }
}
