.settings {
    display: flex;
    flex-grow: 1;

    .setting-menu {
        width: 24%;
        background: #F4F5F8;
        padding: 100px 70px;
        font-family: 'Poppins';

        h5 {
            font-weight: 700;
            font-size: 22px;
            line-height: 28px;
            color: #072136;
            margin: 0;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                margin-top: 20px;
                margin-left: 30px;
                font-weight: 700;
                font-size: 18px;
                line-height: 27px;
                color: #415473;
                cursor: pointer;

                &.active {
                    color: var(--bs-orange);

                    &::after {
                        content: "";
                        display: inline-block;
                        width: 0;
                        height: 0;
                        margin-left: 5px;
                        margin-bottom: 1px;
                        border-top: 5px solid transparent;
                        border-left: 10px solid var(--bs-orange);
                        border-bottom: 5px solid transparent;
                    }
                }

            }
        }
    }

    .setting-content {
        flex-grow: 1;
        padding: 100px;

        .subtitle {
            font-weight: 700;
            font-size: 22px;
            line-height: 28px;
            color: var(--bs-orange);
            margin: 0;
        }

        .title {
            font-weight: 700;
            font-size: 45px;
            line-height: 68px;
            margin: 0;
            margin-bottom: 50px;
        }
    }

    .setting-right {
        width: 12%;
    }
}

.billing-setting {
    .billing-title {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #072136;
        margin: 0 0 40px 0;
    }
}

.plan-setting {

    .active-plan-card {
        background: #FFFFFF;
        border: 1px solid #DADBE2;
        border-radius: 20px;
        padding: 40px 0 60px;

        .plan-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: #5EB5CA;
            margin: 0;
        }

        .plan-content {
            margin-top: 50px;
            display: flex;

            >div:not(.plan-divider) {
                flex-grow: 1;
                flex-shrink: 0;
                flex-basis: 0;
                margin-left: 55px;
                margin-right: 55px;
            }

            .plan-subtitle {
                text-align: center;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                text-align: center;
                color: #415473;
            }

            .plan-divider {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 1px;
                background-color: #DADBE2;
            }

            .plan-points {
                list-style: none;
                padding: 0;

                CheckIcon {
                    margin-right: 10px;
                }

                li {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #415473;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .plan-price {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-weight: 700;
        color: #5EB5CA;
        line-height: 100%;

        .plan-price-digit {
            font-size: 65px;
            height: 65px;
            line-height: 1;
        }

        .plan-price-text {
            line-height: 2.5;
            font-size: 18px;
        }
    }

    .plan-card-wrap {
        display: flex;
        gap: 30px;

        .plan-card {
            flex-grow: 1;
            flex-basis: 0;
            margin-top: 40px;
            padding: 50px 30px;
            background: #F4F5F8;
            border: 1px solid #DADBE2;
            border-radius: 20px;

            .plan-title {
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                text-align: center;
                color: #415473;
            }

            .plan-points {
                list-style: none;
                padding: 0;
                margin: 50px 18px 0;

                CheckIcon {
                    margin-right: 10px;
                }

                li {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #415473;
                    margin-top: 20px;
                }
            }
        }
    }

    .btn-cancel {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #415473;
    }
}

.users-setting {
    max-width: 500px;
    .user-card {
        display: flex;
        align-items: center;
        padding: 20px;
        gap: 20px;
        margin-bottom: 30px;

        &.active {
            background: #FFFFFF;
            box-shadow: 0px 4px 28px rgba(218, 219, 226, 0.6);
        }

        .user-avatar {
            display: inline-block;
            width: 63px;
            height: 63px;
            border-radius: 50%;
            background: #72C4FF;
            font-weight: 700;
            font-size: 22px;
            line-height: 63px;
            text-align: center;
            color: #FFFFFF;
        }

        .user-card-content {
            display: flex;
            flex-direction: column;

            .label-wrap {
                min-height: 30px;
                width: 100%;
                margin-bottom: 5px;
            }

            .user-label {
                display: inline-block;
                padding: 0 10px;
                background: #5EB5CA;
                border-radius: 50px;
                color: #fff;
                font-weight: 400;
                font-size: 14px;
                line-height: 30px;
            }

            .user-edit {
                margin-left: 22px;
                cursor: pointer;
            }

            .user-name {
                margin: 0;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #072136;
            }

            .user-email {
                font-size: 14px;
                line-height: 21px;
                color: #415473;
                opacity: 0.5;
            }
        }
    }

    .user-card-add {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 119px;
        background: #ECEDF0;
        font-size: 16px;
        line-height: 24px;
        color: #566575;
        cursor: pointer;
        .add-icon {
            margin-right: 30px;
        }
    }
}