.Header {
  background-color: #1D2132;
  color: #fff;
  height: 83px;
  .row {
    margin: 0 -25px;
  }
  .col {
    padding: 0 25px;
  }

  &__container {
    padding: 0px 37px 0px 70px;
  }

  a {
    &:hover {
      color: darken(#FF6432, 5);
    }
  }
}
.Trial {
  background-color: #5C89D1;
  color: #fff;
  height: 83px;
  .row {
    margin: 0 -25px;
  }
  .col {
    padding: 0 25px;
  }

  &__container {
    padding: 0px 37px 0px 300px;
  }

  button {
    background-color: #5C89D1;
    color: #fff;
    outline: #fff;
    border: 2px solid #fff;
    padding: 5px 20px 5px 20px;
    border-radius: 20px;
    height: 40px;
  }
  a {
    &:hover {
      color: darken(#FF6432, 5);
    }
  }
}