.PreviousArticles {
  padding: 170px;

  &__container {
    max-width: 1030px;
  }
  &__title {
    font-size: 45px;
    line-height: 68px;
  }

  &__tab {
    font-size: 18px;
    line-height: 27px;
  }

  &__btn {
    padding: 14px 12px;
    background-color: #415473;

    &--asc {
      svg {
        transform: rotate3d(1, 0, 0, 180deg);
      }
    }
  }

  &__orderBtn {
    color: #072136;

    &:hover {
      color: #415473;
      .PreviousArticles__btn {
        background-color: #072136;
      }
    }
  }
}
