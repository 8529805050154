.DataMappingStep {
  color: #415473;
  &__title {
    font-size: 22px;
    line-height: 28px;
  }

  &__box {
    height: 75px;
    border: 1px solid #dadbe2;
    padding: 15px 10px 15px 15px;

    &--gray {
      background: #f4f5f8;
    }
  }

  &__value {
    font-size: 14px;
    line-height: 21px;
  }
}
