.Creator {
  background-color: #f4f5f8;
  padding: 50px 25px 100px;

  &__content {
    width: 327px;
    color: #415473;
  }

  &__link {
    color: #5eb5ca;

    &:hover {
      color: darken($color: #5eb5ca, $amount: 5);
    }
  }

  &__close {
    padding: 0;
    background: transparent;
    border: 0;
    svg {
      rect {
        transition: fill 0.25s;
      }
    }
    &:hover {
      svg {
        rect {
          fill: #072136;
        }
      }
    }
  }

  &__title {
    font-size: 45px;
    line-height: 68px;
    color: #072136;
  }

  &__icon {
    svg {
      width: 118px;
      height: 118px;
    }
  }

  &__step {
    color: #415473;
    opacity: 0.5;

    svg {
      path {
        stroke: #415473;
      }
    }

    &--active {
      opacity: 1;
      color: #072136;

      svg {
        path {
          stroke: #072136;
        }
      }
    }
  }

  &__btn {
    max-width: 182px;
  }

  &__cancel {
    color: #415473;

    &:hover {
      color: #072136;
    }
  }
  &__back {
    transition: color 0.25s;
    color: #072136;

    svg {
      path {
        transition: fill 0.25s;
      }
    }

    &:hover {
      color: #415473;
      svg {
        path {
          fill: #415473 0.25s;
        }
      }
    }
  }
}
