:root {
  --bs-blue: #1070ff;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #f37845;
  --bs-yellow: #ffd702;
  --bs-green: #0da86b;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-white-rgb: 255, 255, 255;
  --bs-gray: #6c757d;
  --bs-gray-dark: #264f3f;
  --bs-dark-rgb: 38, 79, 63;
  --bs-gray-100: #f4f4f4;
  --bs-gray-200: #dadada;
  --bs-gray-300: #dde2e5;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #b6b6b6;
  --bs-gray-600: #929f9a;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #63b796;
  --bs-primary-rgb: 99, 183, 150;
  --bs-secondary: #e7f3ee;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffe609;
  --bs-danger: #fc1111;
  --bs-danger-rgb: 252, 17, 17;
  --bs-light: #f8f9fa;
  --bs-dark: #264f3f;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

@import "./reset.scss";
$enable-negative-margins: true;
$spacer: 1.25rem;
$grid-gutter-width: 1.875rem;

@import "~bootstrap/scss/bootstrap-utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #072136;
}

a {
  transition: color 0.25s;
}

.mb--50 {
  margin-bottom: 50px !important;
}

.pb--70 {
  padding-bottom: 70px;
}
.mb--80 {
  margin-bottom: 80px;
}

.btn {
  background-color: var(--bs-orange);
  padding: 14px 20px;
  color: #fff;
  transition: background-color 0.25s;
  font-weight: 700;
  border-radius: 10px;
  border: none;
  outline: none;

  &:hover {
    background-color: #ff9265;
  }

  &:focus {
    background-color: #db551e;
  }

  &:disabled {
    background-color: #dadbe2;
  }

  &--primary {
    background-color: #415473;
    &:hover {
      background-color: #072136;
    }

    &:focus {
      background-color: #415473;
      outline: none;
    }

    &:disabled {
      background-color: #dadbe2;
    }
  }

  &--outline {
    padding: 11px 20px;
    border: 3px solid #415473;
    border-radius: 10px;
    background-color: transparent;
    color: #415473;
    transition: border-color 0.25s color 0.25s;

    &:hover {
      background-color: #415473;
      color: #fff;
    }

    &:focus {
      background-color: #072136;
      border-color: #072136;
      color: #fff;
    }

    &:disabled {
      color: #dadbe2;
      border: 3px solid #dadbe2;
      background-color: transparent;
    }
  }
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.Paper {
  background-color: #fff;
  border: 1px solid #dadbe2;
  border-radius: 30px;
  padding: 30px 36px 30px 70px;
  max-width: 1202px;
}

.text--orange {
  color: var(--bs-orange);
}

.text--primary {
  color: #415473;
}
.text--secondary {
  color: #5eb5ca;
}

.text--dark {
  color: #072136;
}
.text--light {
  color: #415473;
  opacity: 0.5;
}

.simplebar-scrollbar:before {
  background: #415473 !important;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1 !important;
}

.multiline {
  white-space: pre-line;
}

.transition-color {
  transition: color 0.25s;
}

.Scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.Scrollbar::-webkit-scrollbar-track {
  background-color: #dadbe2;
  border-radius: 40px;
}

.Scrollbar::-webkit-scrollbar-thumb {
  background: #415473;
  border-radius: 40px;
}

.Scrollbar::-webkit-scrollbar-thumb:hover {
  background: darken(#415473, 5);
}
