.Input {
  color: #fff;
  &__input {
    background-color: transparent;
    border: 1px solid #dadbe2;
    border-radius: 10px;
    padding: 15px;
    max-width: 400px;
    color: #fff;
    outline: none;
    transition: border-color 0.25s;

    &--error {
      border: 1px solid #ee465a;
    }
  }

  &__error {
    font-size: 14px;
    line-height: 21px;
    color: #ee465a;
  }

  &.light {
    color: #072136;
    .Input__input {
      color: #415473;
      &:read-only {
        border-color: transparent;
      }
    }
  }

  input:focus {
    border: 1px solid #5eb5ca;
  }
}
.svg-image{
  display: inline-block;
  padding: 5px;
}
